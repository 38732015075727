@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-plan-card {
  background: $color-white;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);

  .uhc-plan-card__header {
    background: $color-blue-primary;
    border-radius: 8px 8px 0 0;
    color: $color-white;
    padding: 20px;
  }

  .uhc-plan-card__main {
    padding: 20px;
    position: relative;
  }

  .uhc-plan-card__footer {
    background: $color-white;
    border-radius: 0 0 8px 8px;
    border-top: $border-base;
    position: relative;
    z-index: 1;

    .uhc-plan-card__footer-inner {
      background: $color-white;
      border-radius: 0 0 8px 8px;
      padding: 20px;
      position: relative;
      z-index: 1;

      @include media-query {
        padding: 20px;
      }
    }
  }

  &.uhc-plan-card--expandable {
    .uhc-plan-card__main {
      height: 160px;
      overflow: hidden;
      padding: 20px 20px 40px;
    }

    .uhc-plan-card__footer {
      &::before {
        border-radius: 10px 10px 0 0;
        background-image: linear-gradient(
          to bottom,
          rgba(168, 168, 168, 0) 12%,
          rgba(159, 159, 159, 0.25) 39%,
          rgba(147, 147, 147, 0.98) 70%,
          #6f6f6f
        );
        content: '';
        display: block;
        height: 60px;
        pointer-events: none;
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
        z-index: 0;
      }

      .uhc-plan-card__footer-inner {
        padding: 40px 20px 20px;

        @include media-query {
          padding: 20px;
        }
      }
    }
  }

  &.uhc-plan-card--expandable.uhc-plan-card--expanded {
    .uhc-plan-card__main {
      height: auto;
      overflow: visible;
    }

    .uhc-plan-card__footer {
      height: auto;

      &::before {
        display: none;
      }
    }
  }

  .uhc-plan-card__expand-button {
    appearance: none;
    background: $color-white;
    border: $border-base;
    border-radius: 9999px;
    color: $color-blue-secondary;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    top: -21px;
    left: 50%;
    margin-left: -105px;
    height: 42px;
    width: 210px;

    &:focus {
      outline: 3px solid $color-blue-secondary;
    }
  }
}
