@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-utility-tag {
  background: #fef9ea;
  border: 1px solid $color-gray-4;
  border-radius: 8px;
  color: $color-gray-8;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-clamp: 2;
  min-height: 54px;
  padding: 10px;
  text-decoration: none;

  &:focus {
    outline-offset: 0;
  }
}

a.uhc-utility-tag,
button.uhc-utility-tag {
  cursor: pointer;
  text-align: left;
}
