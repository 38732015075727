@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-utility-bar {
  background: $color-white;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  border-top: 6px solid $color-gold-primary;
  display: block;
  overflow: hidden;
  padding: 20px;
  width: 100%;

  .uhc-utility-bar__inner {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 20px;

    @include media-query {
      align-items: start;
      flex-direction: row;
      gap: 40px;
    }

    @include media-query(1200px) {
      align-items: start;
    }
  }

  .uhc-utility-bar__icon {
    display: block;
    width: 42px;
    height: 42px;
  }

  .uhc-utility-bar__left {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    @include media-query {
      flex: 1 1 40%;
    }
  }

  .uhc-utility-bar__right {
    @include media-query {
      flex: 1 1 60%;
    }
  }

  .uhc-utility-bar__title {
    font-family: $font-family-serif;
    font-size: 18px;
    margin-bottom: 5px;

    .uhc-utility-bar__title-link {
      color: $color-blue-primary;
      text-decoration: none;
    }

    .uhc-utility-bar__title-icon {
      margin-left: 10px;
      width: 8px;
      height: 12px;
    }
  }

  .uhc-utility-bar__subtitle {
    font-size: 14px;
  }

  .uhc-utility-bar__tags {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;

    .uhc-utility-tag {
      flex: 1;
    }

    @include media-query(1200px) {
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }
  }
}
