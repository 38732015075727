@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-footer {
  background: $color-blue-primary;
  color: $color-white;

  a {
    color: $color-white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: 2px solid $color-teal-primary;
      outline-offset: 2px;
    }
  }

  select {
    appearance: none;
    background-color: transparent;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAwCAYAAABT9ym6AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAMqADAAQAAAABAAAAMAAAAAAPC/zLAAAEgklEQVRoBe2Vu09UYRDFWRcfID4QjIgJURON4RGgsTOxMiY2Nv4FJsR/RBsTCxOj0YAVNbGjo6IkvAIhISFUNDRqoFEent91z2a83N3lLnTeSWZn9puZM3Pmu3e3paWQYgPFBooNFBsoNlBsoNhAsYFiA//LBko5iObJPWyAe5pYSavWBg0Ju6ltLPFZvcEdc27ENJZjzvV5tPVi1SFjQfTdoJaNufhulrbOOymOcY1XtWeqXm2H5lGpQcu7u7tjm5ubQ/iVM8ewsSb6MSfBAQOsFE6swW9aKHZTBj0rPSc9L21T49eHkoODg+8bGxuPdNYuvVCJk0c+jy61KH7EILedWjDAAlNnbVJ6GCMuqSlCJuIhAE6a7+zsvKGxhUGWl5cfK94hvShNDxMJECOngxqTMBbYinkp9PQyfMs6OioEGwmETCp5FPb29v6pK5VKVwYGBr7Nzc09VK4b+yaybCu51FAbB6hgp2+hqZswLsXxNtgkG78q7dre3n7rLdpquz9mZ2efKN4pZcBLUraP4nPWSQ65rrMFU/EuKT3oRc94K00RSrYvIB4Lnlmu+7L0mvSGtHdra+udh7Dd39//OTMz81Tx7koupFDquomR43xbsBTvlYJNLr3oSW9m8C3JzSeRCO8GW2VTDHhT2ie9q1+c9x7GlkGnp6efKd4jZTC0h7MsEmAoflcKJtj0oBc96X2qRPxYXRfwLelt6T3pg/X19Q8mYcvAU1NTzxVnuD78LBLUgiEF67YUbHr48Tp1Ir4RE7mjZvel/dKhtbW1jyZhy+CTk5Mv0CwS1FBbwQALzEjk2DdSUmEtIcbjhfVLH/8LeAlRrp3zMysrK6/6+/vH5FdFBHb5Ui6XGaoqq6urn/Wr9UkHB9I96W/pr6B85xzdl/KvTm7mvzuD1hMXRRD7gBqYs5IG+yIyXyIgBNIkyCGXGim1tTCNDyQ5NaUREQrdyM3YDg2w1mrDwcHB8aWlpXHFMoUYOZVgxIxY+MZ0/0w8Hx6HCLluaAK+7nj1NE+aDg8Pjy8uLk64iS1nxJwn6+EjjrHpZTKGqGnrEfEmovWmsG4ereOHIyMjEwsLC1/dGZ8zfQePARthEI+97ev4qNR72ckm7hxIW/3y86L7hY8vf/UHYH5+/qVyWkZHR8dlIABxXmTUL7d9bPpGqEFORAQAk7E1GX6prFlkiEHYi2AQ34IHh4j9eLMMj3p4Wx1lC42aFcAt+HFgx7AMBAGTiLdwKiSEnWwM26x4YOrTvrdqIgydpSe6CQ/O9R9X4qDUMCDCIAhx1INzA34M5SbnxOLg+L4pYqhxbHXUWPIQAQ3wKDRGGMiNsZybSHzkfO7hsSjnaMRI91K4tuQlAlK6AQNYTIJ3L96G4x44Du+zpkkA3gwR6rLIeBAPBhHfBjWIiZJj33XRJsl5PtKN8tSSS70x7GdZcuOgaT/G8XOLh8hdGAo8OEfGS1unQwDJsj77m5Hz0w1zlh1JN44tCdGPBXFg+7YxL5d/kj/ERo2yhkufpb83wizixQaKDfzvG/gDhxjCmXwrtY0AAAAASUVORK5CYII='),
      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAqCAYAAACgLjskAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHKADAAQAAAABAAAAKgAAAABK3LmEAAACWklEQVRYCb2XPSwEQRzFZ1cUNCqNU4iC5EKDlhyJj4hEpVMKJQWNhASJKzRKJ0o9OonkCIlccqgUPiJRoKGhQ9x577Kb3N7N7s7aGf/kZXdn3rzf7dzM7p0Q/1yWCq9YLHbBNw71QgmoCWK9QM/QGXRgWdYVjn8vgCagW0i16J2ITMSgViinSpH4OLZVCQxjCnqThERtYkYqEEoD9BU1OcDPLDkUHZxGHXdWyWdm9fSiMc53VgmpvM55pha9XI2mq7R6S/sQpFt8gjbPp9B/cYd92m4Bxk19qZJ/dvkoNnZOxcX1U8ne09EsFqb6RG93i8pweroJXMHJctiI9cyxWNo8EoVCwWO1bVuszQ2KxZl+T7vPxSqBWXQGunlnqcntKpgbSujJ7rTKnR7bGMRnY2BxGivvrHwA++hRqASB7oPY1+9+Z74GdKh4yCKwJihIc18NgW9hoVyNYaXiIYvA+7AwLn0uDL9iHz0Kdc+UfJiR+4xLXwZlG/sU92Ke22IYwMMwKPs1bPwRAmuR9Qo1MNRgvSO70cbz7RsnewZBbvQeWe5K2HJbDR69DExtHjJV1QsTpFFTNGZLZw4d5wag51IYGwEbMAAc8AU60KxGKF99wQVYJ6TjpyIzOoNpTi+MaShupZVgNIFUBz3EIHJsnTLQgQ7FAA5FgrlmADN/gGbc8ZGPgNVDNxGg9NZHBpUPQEAX9KkApYe/ceMXguYVgPPxSU4CYHxv7gdA2Vf626AT2oDQOwmUbWZe3ghOQh9lUJ4ntd2VLAiAMejH0ZjMo70NsDloVnuwicBfGnatg2jOC0AAAAAASUVORK5CYII=');
    background-position: right 5px center, left 10px center;
    background-repeat: no-repeat, no-repeat;
    background-size: 25px auto, 14px auto;
    border: 1px solid $color-white;
    border-radius: 4px;
    color: $color-white;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 10px 10px 35px;
    width: 175px;

    &:focus {
      outline: 2px solid $color-teal-primary;
      outline-offset: 2px;
    }
  }

  &__divider {
    background: rgba(255, 255, 255, 0.4);
    height: 1px;
    width: 100%;
  }
}
