@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-tab-navigation {
  border-bottom: 3px solid $color-gray-4;
  padding: 10px;

  .uhc-tab-navigation__nav {
    display: flex;
    gap: 40px;
  }

  .uhc-tab-navigation__tab {
    color: $color-gray-8;
    font-weight: bold;
    position: relative;
    text-decoration: none;

    &:not(:focus-visible) {
      outline: none;
    }

    &.uhc-tab-navigation__tab--current {
      &:after {
        background: $color-gray-8;
        content: '';
        display: block;
        position: absolute;
        right: -10px;
        bottom: -13px;
        left: -10px;
        height: 3px;
      }
    }
  }
}
