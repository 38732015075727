@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-side-nav {
  display: flex;
  flex-direction: column;
  gap: 30px;
  list-style: none;
  margin: 0;
  padding: 0;

  .uhc-side-nav__item {
    font-weight: bold;
    padding: 0;

    &.uhc-side-nav__item--active {
      a {
        color: $color-gray-8;
      }
    }

    a {
      display: flex;
      align-items: center;
      gap: 10px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:not(:focus-visible) {
        outline: none;
      }
    }
  }

  .uhc-side-nav__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    svg {
      display: block;
    }
  }
}
