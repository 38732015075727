@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-collapse-menu {
  background: $color-gray-2;
  border-radius: 8px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.14);

  &.uhc-collapse-menu--expanded {
    .uhc-collapse-menu__trigger-icon {
      transform: rotate(180deg);
    }
  }

  &.uhc-collapse-menu--recommendations {
    .uhc-collapse-menu__panel {
      padding-bottom: 15px;
    }

    .uhc-collapse-menu__list {
      margin-bottom: 15px;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 15px;
        right: 15px;
        height: 1px;
        background: $color-white;
        border-top: 1px solid $color-gray-4;
      }
    }
  }

  .uhc-collapse-menu__trigger {
    appearance: none;
    background: none;
    border: none;
    border-radius: 8px;
    color: $color-gray-8;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    padding: 15px;
    text-align: left;
    width: 100%;

    &:focus {
      outline: 3px solid $color-blue-secondary;
      position: relative;
      z-index: 1;
    }
  }

  .uhc-collapse-menu__title {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .uhc-collapse-menu__item--active .uhc-collapse-menu__link {
    color: $color-gray-8;
  }

  .uhc-collapse-menu__title-icon,
  .uhc-collapse-menu__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    svg {
      display: block;
    }
  }

  .uhc-collapse-menu__list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .uhc-collapse-menu__link {
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    line-height: 20px;
    padding: 15px;
    position: relative;
    text-decoration: none;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 15px;
      right: 15px;
      height: 1px;
      background: $color-white;
      border-top: 1px solid $color-gray-4;
    }

    &:focus {
      outline-offset: 0;
      position: relative;
      z-index: 1;
    }
  }
}
