@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-arrow-panel {
  background-color: $color-white;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 0;
  z-index: 30;

  &[aria-hidden='true'],
  [hidden] {
    display: none;
  }

  .uhc-arrow-panel__arrow {
    position: absolute;
    top: -4px;
    right: 20%;
    width: 20px;
    height: 20px;
    background-color: $color-gold-primary;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    transform: rotate(-45deg);
    transition: all 0.3s ease;
  }

  .uhc-arrow-panel__main {
    background-color: $color-white;
    border-radius: 5px;
    overflow: hidden;
    padding: 6px 0 0;
    position: relative;

    &:before {
      content: '';
      background: $color-gold-primary;
      display: block;
      height: 6px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}
