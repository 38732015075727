@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-sp-card {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;

  &:before {
    content: '';
    background: $color-gold-primary;
    display: block;
    height: 6px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
