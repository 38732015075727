@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-application-card {
  background: $color-white;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;

  .uhc-application-card__header {
    background: $color-blue-primary;
    border-radius: 8px 8px 0 0;
    color: $color-white;
    padding: 20px;
  }

  .uhc-application-card__main {
    padding: 20px;
    position: relative;
  }
}
