@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-flyout-panel {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  z-index: 30;

  &[aria-hidden='true'],
  [hidden] {
    display: none;
  }

  &__inner {
    background: $color-white;
    border: 1px solid $color-gray-3;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  }
}
